:root {
  --color-background: #f0f4f7;
  --color-primary-lighter: #71baf5;
  --color-primary-light: #6badea;
  --color-primary: #5789e5;
  --color-primary-dark: #4d8dd6;
  --color-primary-darker: #4260c2;
  --color-secundary: #91d304;
  --color-secundary-dark: #5ebf04;
  --color-title-in-primary: #FFFFFF;
  --color-text-in-primary: #c2d7ff;
  --color-text-title: #26384d;
  --color-text-complement: #989da6;
  --color-text-base: #616d80;
  --color-line-in-white: #e6eaf0;
  --color-input-background: #f8f9fc;
  --color-button-text: #FFFFFF;
  --color-box-base: #FFFFFF;
  --color-box-footer: #fafafc;

  font-size: 60%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100vh;
}

body {
  background: var(--color-background);
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

button,
input,
textarea,
body {
  font: 500 1.6rem Poppins;
  color: var(--color-text-base);
}

.container {
  width: 90vw;
  max-width: 700px;  
}

@media (min-width: 700px) {
  :root {
    font-size:62.5%;
  }
}